<template>
    <div id="content" class="chat" :class="[$mq]">
        <div class="content full hide-scrollbar">
            <div class="col notes hide-scrollbar" ref="container">
                <Message v-if="trailDaysLeft" :message="trialMessage ? trialMessage : $tc('trial.message', trailDaysLeft == 1 ? 1 : 2, { days: trailDaysLeft, account: accountName })" :hasActions="false" :portraitDeployed="true"> </Message>
                <template v-if="Object.keys(notes).length > 0">
                    <div class="list-notes scrollbar scrollbar-transparent" :class="[{ 'with-trial': trailDaysLeft }, { 'editing-img': isEditingWithImage }, { editing: isEditing }]">
                        <div class="date" v-for="(group, date) in notes" :key="date">
                            <div class="separator">
                                <div class="caption">{{ date }}</div>
                            </div>
                            <note v-for="note in group" :note="note" :key="note.id"></note>
                        </div>
                    </div>
                </template>
                <template v-else-if="notesLoaded">
                    <!-- <div class="empty">{{ $t('notes.empty_notes') }}</div> -->
                    <Message :message="$t('notes.empty_notes')" type="info"></Message>
                    <div v-if="!checkConnetion" class="noConnectionBox">
                        <ConnectionBox class="noConnection" gType="chatConnection" @reload="refreshNotes"></ConnectionBox>
                    </div>
                </template>
                <reply @editingWithImage="editingWithImage" @editingWithoutImage="editingWithoutImage"></reply>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
import Message from '@/components/domain/ui/Message'
import calendar from '@/components/layout/Calendar'
import note from '@/components/domain/home/note'
import reply from '@/components/domain/home/reply'
import ConnectionBox from '@/components/domain/ui/Box'

export default {
    name: 'Chat',
    components: { Message, calendar, note, reply, ConnectionBox },
    data() {
        return {
            //   date: moment(),
            speech: '',
            uniqueEdit: '',
            addNewNote: '',
            addNote: false,
            newNote: {
                id: 'new',
                image: '',
                message: '',
                created_date: '',
                employee: false,
                actions: {},
                comments: {}
            },
            notesLoaded: false,
            isEditingWithImage: false,
            isEditing: false
        }
    },
    computed: {
        moment: function () {
            return moment()
        },
        checkConnetion() {
            return fnCheckConnection()
        },
        date: {
            get() {
                return moment(this.$store.getters['tasks/currentDate'])
            },
            set(value) {
                this.$store.commit('tasks/setCurrentDate', value.format('YYYY-MM-DD'))
            }
        },

        tools() {
            let tools = this.$store.getters['getTools']
            return tools ? Object.keys(tools) : []
        },

        // allNotes() {
        //   // console.log(this.$store.getters['notes/getAllLogs']);
        //   return this.$store.getters["notes/getAllLogs"];
        // },
        notes() {
            var notes = this.$store.getters['notes/getAllLogs']
            var group = {}

            if (notes) {
                var sortedNotes = Object.values(notes).sort((a, b) => (a.created_date > b.created_date ? 1 : b.created_date > a.created_date ? -1 : 0))
                if (sortedNotes) {
                    for (var note in sortedNotes) {
                        var idx = sortedNotes[note]
                        if (idx) {
                            // TODO - Do not translate all dates
                            var date = formatRelative(idx.created_date, 'DD/MM/YYYY', true)
                            if (moment().diff(idx.created_date, 'days') <= 2) {
                                date = i18n.t(date)
                            }
                            if (typeof group[date] == 'undefined') {
                                group[date] = []
                            }
                            group[date].push(idx)
                        }
                    }
                }
            }
            return group
        },

        emp_id() {
            return this.$store.getters['loginUser/getLocalEmployee']
        },
        currentEmployee() {
            if (this.emp_id) {
                var emp = this.$store.getters['employee/getEmployee'](this.emp_id)
            } else {
                var emp = this.$store.getters['loginUser/getLocalLocation']
            }

            return emp
        },
        hasAccess() {
            if (this.currentEmployee) {
                return this.$store.getters['hasAccess'](this.currentEmployee.id, 0)
            } else if (this.isNew) {
                return this.isNew
            } else {
                return false
            }
        },
        trialEndDate() {
            return this.$store.getters['login/getTrialEndDate']
        },
        accountName() {
            const location = this.$store.getters['loginUser/getLocalLocation']
            const account_name = location && typeof location.account_name !== 'undefined' ? location.account_name : ''
            return account_name
        },
        trailDaysLeft() {
            return this.$store.getters['login/getTrialDaysLeft']
        },
        trialMessage() {
            return this.$store.getters['login/getTrialMessage']
        }
    },
    mounted() {
        const self = this

        this.$nextTick(() => {
            self.scrollToBottom()
        })
    },
    methods: {
        editingWithImage(param) {
            this.isEditingWithImage = param
        },
        editingWithoutImage(param) {
            this.isEditing = param
        },
        selectDate(date) {
            let now = moment()
            this.date = moment(date).set({ h: now.get('h'), m: now.get('m') })
            this.load()
        },
        resetText() {
            this.addNewNote = ''
        },

        load() {
            var self = this
            self.$overlay.loading()
            self.refreshNotes(false)
            self.$overlay.hide()
        },
        // reloadChat() {
        //     console.log('reload')
        //     this.load()
        // },
        refreshNotes(noScroll) {
            var self = this

            this.$store.dispatch('notes/loadLogs', {}).then(function (response) {
                self.notesLoaded = true
                if (!noScroll) {
                    self.scrollToBottom()
                }
                self.$overlay.hide()
            })
        },

        navTool(route) {
            let self = this

            var href = ''
            var props = {}

            if (typeof route === 'object') {
                href = route.name
                props = route.params
            } else {
                href = route
            }

            if (self.$store.getters['hasAccess'](self.currentEmployee.id, 0)) {
                self.$router.push({ name: self.redirectPath(href), params: props })
                // self.$router.push(self.redirectPath(route));
            } else {
                this.$popup.login({
                    list: this.$store.getters['employee/getLoginUsers'](0),
                    callAfterAccess: function (response) {
                        if (response) {
                            self.$router.push({
                                name: self.redirectPath(href),
                                params: props
                            })
                            // self.$router.push(self.redirectPath(route));
                            self.$popup.close()
                        }
                    }
                })
            }
        },
        redirectPath(firstResult) {
            return this.$root.redirectPath(firstResult)
        },
        scrollToBottom() {
            setTimeout(() => {
                var container = document.querySelector('.list-notes')
                if (container) {
                    if (container.scrollHeight) {
                        container.scrollTop = container.scrollHeight
                    }
                }
            }, 500)
        },
        onEnd({ lastSentence, transcription }) {
            // `lastSentence` is the last sentence before the pause
            // `transcription` is the full array of sentences
            log('onEnd SPEECH')
            log(lastSentence)
            log(transcription)
            this.speech = transcription.join(' ')
            this.speech = lastSentence
            log(this.speech)
        }
    },
    created() {
        this.load()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.chat {
    > .content {
        @include display-flex();
        @include align-items(flex-start);
        @include justify-content(center);
        background-color: transparent;
        height: 100% !important;
        padding-bottom: 0 !important;
        &.full {
            width: 100% !important;
        }
        .col {
            height: calc(100%);

            .date {
                padding-top: 10px;
            }

            .section {
                display: block;
                width: 100%;
                height: auto;
                padding: 6px 0;
                overflow: auto;
            }

            &.notes {
                height: calc(100%);
                width: 100%;
                overflow: auto;
                padding: 10px 10px 0 10px;
                position: relative;
                display: flex;
                align-items: flex-start;
                flex-direction: column;

                > .empty {
                    margin-top: 60px;
                    padding-top: 85px;
                }

                .separator {
                    @include display-flex();
                    @include justify-content();
                    position: relative;
                    text-align: center;

                    .caption {
                        @include font-size(xs);
                        @include background($color: $neutro);
                        @include border-radius(4px);
                        padding: 6px;
                        font-family: $conden-bold;
                        color: $main-s10;
                        z-index: 1;
                        text-transform: uppercase;
                    }
                    &:before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 0;
                        border-top: 1px solid $inactive;
                        background: $inactive;
                        width: 100%;
                        transform: translateY(-50%);
                    }
                }

                .list-notes {
                    height: calc(100% - 80px);
                    padding: 0px 12px 12px 12px;
                    width: 100%;
                    position: relative;

                    &.with-trial {
                        max-height: calc(100% - 70px - 12px - 100px);
                    }

                    &.editing-img {
                        height: calc(100% - 205px);
                    }
                    &.editing {
                        height: calc(100% - 130px);
                    }
                }
            }
        }
        .noConnectionBox {
            width: 100%;
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
.portrait {
    background-color: $default-bg-color;
    height: auto;
    .content .noConnectionBox {
        display: flex;
        justify-content: center;
    }

    .content.full {
        display: block;
        width: 100%;

        .col {
            &.notes {
                max-width: 100%;
                width: 100%;
                height: auto;
                position: unset;
                display: block;
                height: calc(100vh - 90px);
                padding: 0;

                .list-notes {
                    // padding: 0 10px;
                    padding: 0;
                    height: calc(100% - 80px);
                    // overflow-y: hidden;

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    &.with-trial {
                        max-height: calc(100% - 70px - 12px - 190px);
                    }
                    &.editing {
                        height: calc(100% - 110px);
                    }
                }
            }
        }

        .noConnection {
            width: 200px;
            height: 200px;
        }
    }
}
</style>
